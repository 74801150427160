<template>
<div class="contracDetail">
  <div class="detail">
    <h1>合同详细</h1>
    <h3>竞价编号</h3>
    <h3>竞价物资</h3>
    <h3>数量: {{}} d吨</h3>
    <h3>产地</h3>
    <h3>竞价时间</h3>
    <h3>竞价基价</h3>
    <h3>付款方式</h3>
    <h3>交割地</h3>
    <h3>拆分拍</h3>
    <h3>提货方式</h3>
    <h3>诚意金 万元</h3>
    <h3>联系人</h3>
    <h3>联系方式</h3>
    <h3>指标内容</h3>
    <h3>其他说明</h3>
  </div>

</div>
</template>

<script>
export default {
  name: "contracDetail",
  data() {
    return{

    }
  },
  activated() {
  }
}
</script>

<style scoped>
@import "../assets/css/index.css";
@import "../assets/css/page/contracDetail.scss";
</style>